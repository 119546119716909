import { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Section from './Section'

import CV from '../assets/CV_Sébastien_THUIN.pdf';

import '../style/About.scss'


const About = () => {

  const [age, setAge] = useState(0)
  useEffect(() => {
    const cur = new Date()
    const birth = new Date('1996/08/11')
    setAge(Math.floor((cur - birth) / 31557600000))
  }, [])
  
  return (
    <Section title="A propos" anchor="about" className="about">
        <p>
          Je suis Sébastien Thuin, { age } ans, passionné de nouvelles
          technologies et du monde de l'informatique. <br />
          Mon cursus universitaire m'a permis d'apprendre les bases de la programmation avec
          du C, du Java ainsi que les techno web de
          base, telles que HTML, CSS, Javascript et PHP. <br />
          Etant plutôt attiré par le développement web, je me suis formé en autodidacte sur Vuejs et 
          ai suivi une formation chez OpenClassrooms en tant que développeur web FullStack afin de me perfectionner dans ce domaine !
        </p>
        <div className="about__button">
          <Button href={CV} variant="contained" color="success">
            Télécharger mon CV
          </Button>
        </div>
    </Section>
  )
}

export default About;