import { Box, Card, CardContent, Typography } from '@mui/material'

import {siJava, siJavascript, siMongodb, siMysql, siNodedotjs, siPhp, siPostgresql, siReact, siVuedotjs} from 'simple-icons/icons'

import Section from "./Section"

import '../style/Skills.scss'

const Skills = () => {
  const skills = [
    {
        name: "Frontend",
        tech: [
            {
                img: siJavascript,
                alt: "Javascript",
                desc: "Utilisation de Javascript pour tous mes projets web hors projets uniquement HTML/CSS"
            },
            {
                img: siVuedotjs,
                alt: "VueJs",
                desc: "Utilisation de VueJS pour un projet personnel"
            },
            {
                img: siReact,
                alt: "ReactJs",
                desc: "Utilisation de ReactJs pour le dernier projet de la formation développeur web d'OpenClassrooms"
            }
        ]
    },
    {
        name: "Backend",
        tech: [
            {
                img: siNodedotjs,
                alt: "NodeJs",
                desc: "Utilisation de NodeJs avec principalement ExpressJS pour gérer le backend de mes projets"
            },
            {
                img: siPhp,
                alt: "Php",
                desc: "Apprentissage de Php pendant mes années de DUT informatique pour certains projets"
            },
            {
                img: siJava,
                alt: "Java",
                desc: "Apprentissage de Java pendant mes années de DUT informatique pour certains projets"
            }
        ]
    },
    {
        name: "Database",
        tech: [
            {
                img: siMysql,
                alt: "MySQL",
                desc: "Utilisation de MySQL pendant mon DUT informatique ainsi que pendant le projet 7 de la formation développeur web d'OpenClassrooms avec Sequelize"
            },
            {
                img: siMongodb,
                alt: "MongoDb",
                desc: "Utilisation de MongoDb pour certains projets de la formation développeur web d'OpenClassrooms nécessitant du noSQL"
            },
            {
                img: siPostgresql,
                alt: "PostgreSQL",
                desc: "Apprentissage de PostgreSQL pendant mon DUT informatique en tant que langage principal de base de données"
            }
        ]
    },
]
  return (
    <Section title="Mes compétences" anchor="competences">
      <Box className="skills">
        {skills.map(specs => (
          <Box className="skills__spec" key={specs.name}>
            <Typography variant="h4" className="skills__spec__name">
              {specs.name}
            </Typography>
            <Box className="skills__tech">
              {specs.tech.map(tech => (
                <Card key={tech.alt}>
                  <CardContent className="skills__tech__card">
                    <Box>
                      <svg role="img" viewBox="0 0 24 24" height="100" xmlns="http://www.w3.org/2000/svg">
                        <title>{tech.img.title}</title>
                        <path fill={`#${tech.img.hex}`} d={tech.img.path} />
                      </svg>
                    </Box>
                    <Typography variant="h6">
                      {tech.alt}
                    </Typography>
                    <Typography>
                      {tech.desc}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Section>
  )
}

export default Skills