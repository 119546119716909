import { Icon } from "@mui/material";
import { Button } from "@mui/material";
import '../style/Header.scss'

const Header = ({ menu }) => {
  return (
    <header className="header">
      <div className="header__title">
        Portfolio
      </div>
      <nav className="header__action">
        {menu.map((element, i) => (
          <Button key={i} className="header__action__button" onClick={() => window.location.hash = element.anchor} sx={{color: "white"}}><Icon>{element.icon}</Icon>{element.text}</Button>
        ))}
      </nav>
    </header>
  )
}

export default Header;
