import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab"
import { Box, Button, Card, CardActions, CardContent, Divider, Icon, Typography } from "@mui/material"
import Section from "./Section"

import '../style/Diplome.scss'

const Diplome = () => {

  const diplome = [
    {
      enonce: 'Formation Développeur Web',
      lieu: 'OpenClassrooms',
      date: 'De juillet 2021 à février 2022',
      more: 'Diplôme RNCP de niveau 5 (Bac +2), Formation de développeur web FullStack Javascript avec NodeJs',
      link: 'https://openclassrooms.com/fr/paths/185-developpeur-web'
    },
    {
      enonce: 'DEUST IOSI',
      lieu: 'Université Polytechnique Haut-de-France • Valenciennes (59)',
      date: 'De septembre 2019 à juin 2020',
      more: "Arrêt de la formation car celle-ci n'apportait rien à mon projet professionnel notamment à la suite d'un DUT",
      link: 'https://formations.uphf.fr/cdm/program/FR_RNE_0593279U_PR_S2IOS261'
    },
    {
      enonce: 'DUT informatique',
      lieu: 'Université Polytechnique Haut-de-France • Maubeuge (59)',
      date: 'De septembre 2016 à juillet 2019',
      more: 'Année 1 et semestre 3 validé',
      link: 'https://formations.uphf.fr/cdm/program/FR_RNE_0593279U_PR_I2INF261'
    },
    {
      enonce: 'Baccalauréat Scientifique option ISN',
      lieu: 'Lycée Gambetta • Arras (62)',
      date: '2016',
    }
  ]

  return (
    <Section title="Diplôme et formations" anchor="diplome">
      <Timeline position="alternate">
        {diplome.map(formation => (
          <TimelineItem key={formation.enonce}>
            <TimelineOppositeContent color="text.secondary" sx={{py: '1em'}}>
              {formation.date}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot>
                <Icon sx={{color: '#e57373'}}>noise_control_off</Icon>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <Card>
                <CardContent>
                  <Box className="card__content">
                    <Typography>
                      {formation.enonce}
                    </Typography>
                    <Typography color="text.secondary">
                      {formation.lieu}
                    </Typography>
                    {formation.more &&
                      <>
                        <Divider variant="middle"/>
                        <Typography color="text.secondary">
                          {formation.more}
                        </Typography>
                      </>
                    }
                  </Box>
                </CardContent>
                {formation.link &&
                  <CardActions sx={{display: 'flex', justifyContent: 'center'}}>
                    <Button href={formation.link}>
                      Vers le site du diplome
                    </Button>
                  </CardActions>
                }
              </Card>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Section>
  )
}

export default Diplome