import { Box, IconButton, Typography } from '@mui/material'
import { siGmail, siLinkedin, siTwitter } from 'simple-icons/icons'

import Section from "./Section"

import '../style/Contact.scss'

const Contact = () => {
  const contacts = [
    {
      name: "Via linkedin : ",
      link: "https://www.linkedin.com/in/sthuin/",
      icon: siLinkedin
    },
    {
      name: "Par mail : ",
      link: "mailto:contact@sthuin.dev",
      icon: siGmail
    },
    {
      name: "Sur twitter : ",
      link: "https://twitter.com/SebThuin",
      icon: siTwitter
    }
  ]

  return (
    <Section title="Me contacter" anchor="contact">
      <Box className="contact">
        {contacts.map(contact => (
          <Box className="contact__links">
            <Typography>
              {contact.name}
            </Typography>
            <IconButton href={contact.link}>
              <svg role="img" viewBox="0 0 24 24" height="24" xmlns="http://www.w3.org/2000/svg">
                <title>{contact.icon.title}</title>
                <path fill={`#${contact.icon.hex}`} d={contact.icon.path} />
              </svg>
            </IconButton>
          </Box>
        ))}
      </Box>
    </Section>
  )
}

export default Contact