import { Box, Typography } from "@mui/material"

import '../style/Section.scss'

const Section = (props) => {
  const { title, anchor, children } = props
  return (
    <section id={anchor} className="section">
      <Typography variant="h3" className="section__title">
        {title}
      </Typography>
      <Box className="section__body">
        {children}
      </Box>
    </section>
  )
}

export default Section