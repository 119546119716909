import '../style/Footer.scss'

const Footer = () => {
  return (
    <footer className="footer">
      <strong>©</strong> {new Date().getFullYear() } - <strong>Sébastien Thuin</strong>
    </footer>
  )
}

export default Footer;