import { Box, Button, Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material"
import Section from "./Section"

import "../style/Project.scss"

import vitrine from "../assets/projects/vitrine.webp"
import P2 from "../assets/projects/P2.webp"
import P3 from "../assets/projects/P3.webp"
import noImg from "../assets/projects/no-image.webp"

const Project = () => {

  const projects = [
    {
        img: vitrine,
        desc: "Site vitrine",
        spec: "Réalisé à l'aide de VueJs et Vuetify",
        link: "https://vitrine.sthuin.dev/"
    },
    {
        img: P2,
        desc: "Projet 2 de la formation Développeur web d'OpenClassrooms",
        spec: "Réalisation d'une maquette desktop avec HTML et CSS.",
        link: "https://prolias.github.io/SebastienThuin_2_11062021/",
        github: "https://github.com/Prolias/SebastienThuin_2_11062021"
    },
    {
        img: P3,
        desc: "Projet 3 de la formation Développeur web d'OpenClassrooms",
        spec: "Réalisation d'un maquette mobile avec HTML et CSS.",
        link: "https://prolias.github.io/SebastienThuin_3_08072021/",
        github: "https://github.com/Prolias/SebastienThuin_3_08072021"
    },
    {
        img: noImg,
        desc: "Projet 5 de la formation Développeur web d'OpenClassrooms",
        spec: "Réalisation du frontend d'un site à l'aide de Javascript avec interdiction d'utiliser un framework ou une librairie.",
        github: "https://github.com/Prolias/SebastienThuin_5_01102021"
    },
    {
        img: noImg,
        desc: "Projet 6 de la formation Développeur web d'OpenClassrooms",
        spec: "Réalisation du backend d'un site à l'aide de ExpressJs.",
        github: "https://github.com/Prolias/SebastienThuin_6_30102021"
    },
    {
        img: noImg,
        desc: "Projet 7 de la formation Développeur web d'OpenClassrooms",
        spec: "Réalisation d'un réseau social d'entreprise avec l'aide du framework : ReactJs et son UI : MUI pour le Frontend et ExpressJs avec sequelize pour gérer le SQL pour le Backend.",
        github: "https://github.com/Prolias/P7_thuin_sebastien"
    }
]

  return (
    <Section title="Mes projets" anchor="projets">
      <Box className="projets_list">
        {projects.map((projet, i) => (
          <Card className="card" key={i}>
            <CardMedia 
              component="img"
              alt={projet.desc}
              image={projet.img}
              className="card__media"
            />
            <CardContent className="card__body">
              <Typography className="card__body__desc" sx={{fontWeight: '600'}}>
                {projet.desc}
              </Typography>
              <Typography className="card__body__spec">
                {projet.spec}
              </Typography>
            </CardContent>
            <CardActions className="card__action">
              {projet.link &&
                <Button href={projet.link} variant="outlined">
                  Vers le site
                </Button>
              }
              {projet.github &&
                <Button href={projet.github} variant="outlined">
                  Vers le github
                </Button>
              }
            </CardActions>
          </Card>
        ))}
      </Box>
    </Section>
  )
}

export default Project