import Header from './components/Header';
import About from './components/About';
import Diplome from './components/Diplome';
import Skills from './components/Skills';
import Project from './components/Project';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Divider } from '@mui/material';

const App = () => {
  const menu = [
    {
      icon: "person",
      text: 'A propos',
      anchor: 'about'
    },
    {
      icon: "school",
      text: 'Diplômes et formations',
      anchor: 'diplome'
    },
    {
      icon: "terminal",
      text: 'Compétences',
      anchor: 'competences'
    },
    {
      icon: "integration_instructions",
      text: 'Mes projets',
      anchor: 'projets'
    },
    {
      icon: "email",
      text: 'Contact',
      anchor: 'contact'
    }
  ]

  return (
    <>
      <Header menu={menu} />
      <main className="main">
        <About />
        <Divider variant='middle' />
        <Diplome />
        <Divider variant='middle' />
        <Skills />
        <Divider variant='middle' />
        <Project />
        <Divider variant='middle' />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default App;
